import { axiosInstance } from 'src/services/axiosInstance';
import { SiteUrl } from 'src/types/app.types';
import { Authentication } from '../authentication/types';
import { CategoryService, Service } from './types';

export const getAPIServices = (action: Authentication) => {
  return axiosInstance.get<{ services: Service[]; technician: object; workingHours: string[] }>(
    action.url + `/api/technicians/${action.adminid}/services?api_token=${action.api_token}`
  );
};

export const getAPIServiceCategories = (action: Authentication) => {
  return axiosInstance.get<CategoryService[]>(
    action.url + `/api/categories?api_token=${action.api_token}`
  );
};
