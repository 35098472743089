import React from 'react';

const NHomePage = React.lazy(() => import('./pages/nail/NHomePage'));
const NotFound = React.lazy(() => import('./pages/404/NotFound'));
const Appointment = React.lazy(() => import('./pages/appointment/Description'));

const routes = [
  { path: '/', exact: true, name: 'Dashboard', element: NHomePage },
  { path: '/appointment', name: 'Appointment', element: Appointment },
  { path: '*', name: 'ForgotPassword', element: NotFound },
];

export default routes;
