/**
 * The event send to the store
 */

import { createAction } from '@reduxjs/toolkit';
import { BookingForm, BookingInfoBody, BookingNewAppointment, SubmitBooking } from './types';

export const setBookingForm = createAction<BookingForm>('booking/set-form');
export const setBookingInfo = createAction<BookingInfoBody>('booking/setBookingInfo');
export const setCurrentStep = createAction<number>('booking/set-current-step');

export const submitBookingForm = createAction<SubmitBooking>('booking/submit-booking-form');
export const submitBookingFormSuccess = createAction<BookingNewAppointment>(
  'booking/submit-booking-form/success'
);
export const submitBookingFormError = createAction('booking/submit-booking-form/error');

export const getLookupPhone = createAction<SubmitBooking>('booking/getLookupPhone');
export const getLookupPhoneSuccess = createAction<any>('booking/getLookupPhone/success');
export const getLookupPhoneError = createAction('booking/getLookupPhone/error');
