import React, { Suspense, useEffect } from 'react';
import './App.css';
import { HashRouter, Route, Routes, useParams, useSearchParams } from 'react-router-dom';
import routes from './routes';
import PageLoading from './components/loading/PageLoading';
import AppStyle from './AppStyle';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Containers
const MainLayout = React.lazy(() => import('./layout/MainLayout'));

function App() {
  return (
    <>
      <HashRouter>
        <Suspense fallback={<PageLoading loading={true} />}>
          <Routes>
            <Route path="/" element={<MainLayout />}>
              {routes.map((route, idx) => {
                return (
                  route.element && <Route key={idx} path={route.path} element={<route.element />} />
                );
              })}
            </Route>
          </Routes>
        </Suspense>
      </HashRouter>
      <ToastContainer />
    </>
  );
}

export default App;
