/**
 * For process asynchronous
 * Handle the action and terminate
 */

import { call, put, SagaReturnType } from 'redux-saga/effects';
import { ActionSaga } from 'src/types/saga.types';
import {
  getLookupPhoneError,
  getLookupPhoneSuccess,
  submitBookingFormError,
  submitBookingFormSuccess,
} from './actions';
import { getLookupPhoneAPI, submitBookingFormAPI } from './apis';
import { SubmitBooking } from './types';

export function* submitBookingFormWorker(action: ActionSaga<SubmitBooking>) {
  try {
    const { payload } = action || {};
    if (payload?.url) {
      const { data }: SagaReturnType<typeof submitBookingFormAPI> = yield call(
        submitBookingFormAPI,
        payload
      );
      yield put(submitBookingFormSuccess(data.data));

      //redirect to appointment page
      const query = document.location.hash?.split('#/?')?.[1];
      window.location.replace(`#/appointment?${query}`);
    }
  } catch (e) {
    yield put(submitBookingFormError());
    console.error('Error', e);
  }
}

export function* getLookupPhoneWorker(action: ActionSaga<SubmitBooking>) {
  try {
    const { payload } = action || {};
    if (payload?.url) {
      const { data }: SagaReturnType<typeof getLookupPhoneAPI> = yield call(
        getLookupPhoneAPI,
        payload
      );
      yield put(getLookupPhoneSuccess(data.data));
    }
  } catch (e) {
    yield put(getLookupPhoneError());
    console.error('Error', e);
  }
}
