import { axiosInstance } from 'src/services/axiosInstance';
import { BookingNewAppointment, SubmitBooking } from './types';

export const submitBookingFormAPI = (action: SubmitBooking) => {
  return axiosInstance.post<{ data: BookingNewAppointment }>(
    action.url + `/api/book-appointment?api_token=${action.api_token}`,
    action
  );
};

export const getLookupPhoneAPI = (action: SubmitBooking) => {
  return axiosInstance.get<{ data: { name: string; userid: string } }>(
    action.url + `/api/customers/${action.phone}/look-up?api_token=${action.api_token}`,
    action
  );
};
