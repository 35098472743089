/**
 * For process asynchronous
 * Watch for dispatched actions and fork a worker on every action
 */

import { takeLatest } from 'redux-saga/effects';
import { getLookupPhone, submitBookingForm } from './actions';
import { getLookupPhoneWorker, submitBookingFormWorker } from './workers';

export function* bookingSaga() {
  yield takeLatest(submitBookingForm, submitBookingFormWorker);
  yield takeLatest(getLookupPhone, getLookupPhoneWorker);
}
