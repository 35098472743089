/**
 * For process asynchronous
 * The pure functions to get current state, work the action and return new a state
 */

import { createReducer } from '@reduxjs/toolkit';
import {
  getLookupPhoneError,
  getLookupPhoneSuccess,
  setBookingForm,
  setBookingInfo,
  setCurrentStep,
  submitBookingForm,
  submitBookingFormError,
  submitBookingFormSuccess,
} from './actions';
import { getMaxStep } from './helpers';
import { BookingReducer } from './types';

const initialState: BookingReducer = {
  bookingForm: {
    maxStep: 0,
  },
};

export const bookingReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setBookingForm, (state, { payload }) => {
      // if step 1 change other staff then remove date time and revert max step:
      const { time, ...others } = state?.bookingForm || {};

      if (payload?.staff?.adminid && others?.staff?.adminid !== payload.staff.adminid) {
        state.bookingForm = {
          ...others,
          ...payload,
          maxStep: Math.min(getMaxStep(state.bookingForm?.maxStep, payload?.step), 2),
        };
      } else {
        state.bookingForm = {
          ...state.bookingForm,
          ...payload,
          maxStep: getMaxStep(state.bookingForm?.maxStep, payload?.step),
        };
      }
    })
    .addCase(setBookingInfo, (state, action) => {
      const currentInfo = state.bookingForm?.info || {};

      state.bookingForm = {
        ...state.bookingForm,
        info: { ...currentInfo, ...action.payload },
      };
    })
    .addCase(setCurrentStep, (state, action) => {
      state.bookingForm = { ...state.bookingForm, step: action?.payload };
    })
    .addCase(submitBookingForm, (state) => {
      state.ui = { ...state.ui, loadingBtn: true };
    })
    .addCase(submitBookingFormSuccess, (state, { payload }) => {
      state.ui = { ...state.ui, loadingBtn: false };
      state.newAppointment = { ...payload };
      // clear booking form
      state.bookingForm = undefined;
    })
    .addCase(submitBookingFormError, (state) => {
      state.ui = { ...state.ui, loadingBtn: false };
    })
    .addCase(getLookupPhoneSuccess, (state, { payload }) => {
      if (payload?.name) {
        state.bookingForm = {
          ...state.bookingForm,
          info: { ...state.bookingForm?.info, name: payload.name },
        };
      }
    })
    .addCase(getLookupPhoneError, (state) => {
      state.ui = { ...state.ui };
    })
    .addDefaultCase((state, action) => {});
});
