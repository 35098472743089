/**
 * The event send to the store
 */

import { createAction } from '@reduxjs/toolkit';
import { Authentication } from '../authentication/types';
import { CategoryService, Service } from './types';

export const getServices = createAction<Authentication>('get/services');
export const getServicesSuccess = createAction<Service[]>('get/services_success');
export const getServicesError = createAction('get/services_error');

export const getServiceCategories = createAction<Authentication>('get/getServiceCategories');
export const getServiceCategoriesSuccess = createAction<CategoryService[]>(
  'get/getServiceCategories/success'
);
export const getServiceCategoriesError = createAction('get/getServiceCategories/error');
